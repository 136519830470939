module.exports = {
  form: {
    username: "",
    password: "",
    verificationCode: "",
    confirmPassword: "",
    email: "",
    phoneNumber: "",
    invitationCode: ""
  },
  rules: {
    emailRules: [
      v => !!v || "邮箱为必填",
      v => /.+@.+\..+/.test(v) || "邮箱不可用"
    ],
    usernameRules: [
      v => !!v || "用户名为必填",
      v => (v && v.length <= 10) || "用户名请小于10个字符"
    ],
    phoneNumberRules: [
      v => !!v || "手机号为必填",
      v => (v && v.length == 11) || "手机号格式无效"
    ],
    verificationCodeRules: [v => !!v || "请填写验证码"],
    passwordRules: [v => !!v || "密码为必填"],
    oldPasswordRules: [v => !!v || "旧密码为必填"],
    fileRules: [
      value => !value || value.size < 2000000 || "图片大小不得超过2MB!"
    ],
    nameRules: [v => !!v || "姓名为必填"],
    idCardRules: [v => !!v || "身份证为必填"],
    companyRules: [v => !!v || "公司名为必填"],
    busnessLicenseRules: [v => !!v || "工商执照为必填"]
  }
};
