<template>
  <v-container>
    <v-card elevation="0" class="indigo" style="background: linear-gradient(45deg, #03A9F4, #0000007d);">
      <v-card-title class="">
        <span>{{ title }}</span>
        <v-spacer></v-spacer>

        <v-btn text class="white--text" @click="createUser" v-if="role != 'proUser'">
          新建用户
        </v-btn>
      </v-card-title>
    </v-card>
    <!-- 时间线 -->
    <v-row>
      <v-col cols="12" sm="5" offset="7" align-self="center">
        <v-text-field v-model="searchUser" solo dense class="" append-icon="mdi-magnify" label="搜索" single-line
          hide-details></v-text-field>
      </v-col>
    </v-row>
    <v-data-table :headers="tableHeaders" :items="userList" :search="searchUser" class="order-table-hover">
      <template v-slot:[`item.applyStatus`]="{ item }">
        <v-chip :color="getStatusColor(item.applyStatus)" dark>
          {{ getStatus(item.applyStatus) }}
        </v-chip>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon small class="mr-2" @click="editItem(item)" v-on="on" v-bind="attrs">
              mdi-pencil
            </v-icon>
          </template>
          <span>修改</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon small class="mr-2" @click="reloadPws(item)" v-on="on" v-bind="attrs">
              mdi-reload
            </v-icon>
          </template>
          <span>重置密码</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon small @click="del(item)" v-on="on" v-bind="attrs">
              mdi-close
            </v-icon>
          </template>
          <span>删除</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-dialog v-model="creatDialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="form">
              <v-row>
                <v-col cols="12">
                  <v-text-field v-model="form.username" :disabled="!formType" label="用户名"
                    :rules="formRules.usernameRules">
                  </v-text-field>
                </v-col>
                <v-col cols="12" v-if="formType">
                  <v-text-field v-model="form.password" label="密码" type="password" required
                    :rules="formRules.passwordRules">
                  </v-text-field>
                </v-col>
                <v-col cols="12" v-if="formType">
                  <v-text-field v-model="form.confirmPassword" label="确认密码" type="password" required
                    :rules="rules.confirmPasswordRules"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field label="邮箱" v-model="form.email" required :rules="formRules.emailRules"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field label="手机号" v-model="form.phoneNumber" :rules="formRules.phoneNumberRules" required>
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field label="邀请码" v-model="invitationCode" disabled required></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="creatDialog = false">
            取消
          </v-btn>
          <v-btn color="blue darken-1" text v-if="formType" @click="addUser">
            创建
          </v-btn>
          <v-btn color="blue darken-1" text v-else @click="editUser">
            保存
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
// let vm;
import axios from "axios"; //基于Promise的HTTP客户端
import rules from "../../config/rules";
// //import qs from "qs"
import commonCfg from "../../config/common";
let commonUrl = commonCfg.commonUrl;
let version = commonCfg.urlVersion;
// let commonUrl = "http://192.168.1.104:64011/";
// let version = "";
export default {
  data () {
    return {
      title: "用户管理",
      userList: [],
      formType: true, // true-创建 false-修改
      creatDialog: false,
      // 添加用户表单
      form: {
        username: "",
        password: "",
        confirmPassword: "",
        email: "",
        phoneNumber: ""
      },
      tableHeaders: [
        {
          text: "用户名",
          align: "start",
          sortable: false,
          value: "username"
        },
        { text: "电话", sortable: false, value: "phoneNumber" },
        { text: "邮箱", sortable: false, value: "email" },
        { text: "认证状态", sortable: false, value: "applyStatus" },
        { text: "操作", sortable: false, value: "actions" }
      ],
      formRules: rules.rules,
      rules: {
        confirmPasswordRules: [
          v => !!v || "确认密码为必填",
          v => v === this.form.password || "密码不一致"
        ]
      },
      searchUser: ""
    };
  },
  async created () {
    this.getUserList();
  },
  watch: {
    form: {
      deep: true,
      handler (val) {
        val.username = val.username.toLowerCase();
      }
    }
  },
  props: ["role", "username", "invitationCode"],
  computed: {
    formTitle () {
      return this.formType ? "新建用户" : "修改用户";
    }
  },
  methods: {
    // 获取项目管理员下的用户
    getUserList () {
      axios.get(`${commonUrl}listProjectCustomer${version}`).then(res => {
        console.log(res);
        this.userList = res.data.result;
      });
    },
    // 根据认证状态显示颜色
    getStatusColor (status) {
      let color = "blue-grey";
      switch (status) {
        case "pass":
          color = "green";
          break;
        case "checking":
          color = "orange";
          break;
        case "refuse":
          color = "red";
          break;
        default:
          color = "blue-grey";
      }
      return color;
    },
    // 显示认证状态
    getStatus (status) {
      let str = "未认证";
      switch (status) {
        case "pass":
          str = "已认证";
          break;
        case "checking":
          str = "认证中";

          break;
        case "refuse":
          str = "认证失败";

          break;
        default:
          str = "未认证";
      }
      return str;
    },
    createUser () {
      this.form = {};
      this.creatDialog = true;
      this.formType = true;
    },
    del (item) {
      this.$dialog
        .confirm({
          text: "是否删除此账号",
          title: "删除账号",
          cancel: "取消",
          actions: [
            {
              text: "取消",
              color: "blue",
              key: "false"
            },
            {
              text: "确认",
              color: "blue",
              key: true
            }
          ]
        })
        .then(type => {
          if (type) {
            var formData = new FormData();
            formData.append("username", item.username);
            axios
              .post(`${commonUrl}deleteCustomer${version}`, formData)
              .then(res => {
                if (res.data.code == "200") {
                  console.log(res);
                  this.$emit("tips", "删除成功！", "green");
                  this.getUserList();
                } else if (res.data.message != null) {
                  this.$emit("tips", res.data.message, "red");
                } else {
                  this.$emit("tips", "删除失败！", "red");
                }
              });
          }
        });
    },
    // 修改用户资料
    editItem (item) {
      this.form = {
        username: item.username,
        password: item.password,
        confirmPassword: item.password,
        email: item.email,
        phoneNumber: item.phoneNumber,
        invitationCode: this.invitationCode
      };
      this.formType = false;
      this.creatDialog = true;
    },
    // 重置用户密码
    reloadPws (item) {
      this.$dialog
        .confirm({
          text: "是否重置此用户密码",
          title: "重置密码",
          cancel: "取消",
          actions: [
            {
              text: "取消",
              color: "blue",
              key: "false"
            },
            {
              text: "确认",
              color: "blue",
              key: true
            }
          ]
        })
        .then(type => {
          if (type) {
            let formData = new FormData();
            formData.append("username", item.username);
            axios
              .post(`${commonUrl}reloadPws${version}`, formData)
              .then(res => {
                if (res.data.code == "200") {
                  this.$emit("tips", "重置密码成功！", "green");
                  this.getUserList();
                } else if (res.data.message != null) {
                  this.$emit("tips", res.data.message, "red");
                } else {
                  this.$emit("tips", "重置密码失败！", "red");
                }
              });
          }
        });
    },
    // 添加新用户
    addUser () {
      if (this.$refs.form.validate()) {
        let param = {
          username: this.form.username,
          password: this.form.password,
          email: this.form.email,
          phoneNumber: this.form.phoneNumber,
          invitationCode: this.invitationCode
        };
        axios
          .post(`${commonUrl}addProjectCustomer${version}`, param)
          .then(res => {
            if (res) {
              if (res.data.code == "200") {
                console.log(res);
                this.$emit("tips", "注册成功！", "green");
                this.creatDialog = false;
                this.getUserList();
              } else if (res.data.message != null) {
                this.$emit("tips", res.data.message, "red");
              } else {
                this.$emit("tips", "注册失败！", "red");
              }
            }
          });
      }
    },
    // 修改用户资料
    editUser () {
      if (this.$refs.form.validate()) {
        axios
          .post(`${commonUrl}editProjectCustomer${version}`, this.form)
          .then(res => {
            if (res.data.code == "200") {
              console.log(res);
              this.$emit("tips", "修改成功！", "green");
              this.creatDialog = false;
              this.getUserList();
            } else if (res.data.message != null) {
              this.$emit("tips", res.data.message, "red");
            } else {
              this.$emit("tips", "修改失败！", "red");
            }
          });
      }
    }
  }
};
</script>
